import request,{ payService } from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 完结订单
export function completion(parkingOrderIntoId, orderAmount, source) {
    return payService.post('/account/completion?parkingOrderIntoId=' + parkingOrderIntoId+'&orderAmount='+orderAmount+'&source='+source)
}

// 场内码查询订单详情
export function getPayCloseParkInner(parkinglotId, plateNumber, plateColor, source) {
    return payService.post('/account/getPayCloseParkInner?parkinglotId=' + parkinglotId+
        '&plateNumber='+plateNumber+
        '&plateColor='+plateColor+
        '&source='+source)
}

// 场内码查询订单详情
export function unicodeSendCoupon(params) {
    return payService.post('/merchant/coupon/unicodeSendCoupon?' + objectToQueryString(params))
}

// 车道码查询订单详情
export function getCloseParkGatePayInner(parkinglotId, parkingGateId, source) {
    return payService.post('/account/getCloseParkGatePay?parkinglotId=' + parkinglotId+
        '&parkingGateId='+parkingGateId+
        '&source='+source)
}

// 车道码查询订单列表
export function getCloseParkGatePayList(parkinglotId, parkingGateId, source) {
    return payService.post('/account/getCloseParkGatePayList?parkingLotId=' + parkinglotId+
        '&parkingGateId='+parkingGateId+
        '&source='+source)
}

// 根据停车订单id查询未支付订单列表
export function getParkingOrderPayList(parkingOrderIntoId, source) {
    return payService.post('/account/getParkingOrderPayList?parkingOrderIntoId=' + parkingOrderIntoId+
        '&source='+source)
}

// 场内码查询订单列表
export function getPayCloseParkInnerList(parkinglotId, plateNumber, plateColor, source) {
    return payService.post('/account/getPayCloseParkInnerList?parkingLotId=' + parkinglotId+
        '&plateNumber='+plateNumber+
        '&plateColor='+plateColor+
        '&source='+source)
}

// 无牌入场
export function noPlateNumDriveIn(params) {
    return payService.post('noPlateNumber/driveIn?' + objectToQueryString(params))
}

// 无牌出场
export function noPlateNumDriveOut(params) {
    return payService.post('noPlateNumber/driveOut?' + objectToQueryString(params))
}

// 无牌出场获取订单列表
export function noPlateParkingDriveOutList(params) {
    return payService.post('noPlateNumber/noPlateParkingDriveOutList?' + objectToQueryString(params))
}

export function accountPay(params) {
    return payService.post('/account/pay?' + objectToQueryString(params))
}


export function getOpenId(code, type) {
    return payService.post('/account/getOpenId?code=' + code+'&type='+type)
}


export function getOrderDetail(parkingOrderIntoId) {
    // parkingOrderIntoId = '1748682078007988227'
    return payService.post('/account/getOrderDetail?parkingOrderIntoId=' + parkingOrderIntoId)
}

export function getLeaseOrderDetail(orderIntoId) {
    // parkingOrderIntoId = '1748682078007988227'
    return payService.post('/parkinglotWx/leaseManager/leaseOrderDetail?orderId=' + orderIntoId)
}

//查询运营人员列表
export function getOperators(params) {
    return request.post('/system/memberList?' + objectToQueryString(params))
}

//添加运营人员
export function addOperators(body) {
    return request.post('/system/memberAdd?' + objectToQueryString(body))
}

//编辑运营人员
export function updateOperators(body) {
    return request.post('/system/memberEdit?' + objectToQueryString(body))
}

//修改运营人员密码
export function updatepassword(body) {
    return request.post('/system/changePassword', body)
}

//删除运营人员
export function removeOperators(body) {
    return request.post('/system/memberDel', body)
}

//查找角色
export function getRole() {
    return request.get('/platform/operator/get?pageNo=1&pageSize=100')
}

//设置角色添加
export function adduserrole(body) {
    return request.post('/system/setRole?' + objectToQueryString(body))
}

//获取设置角色列表
export function getuserrole(userId) {
    return request.post('/system/findRoleByUserId?userId=' + userId)
}

//删除设置角色
export function removeuserrole(body) {
    return request.post('/platform/operator/removeuserrole', body)
}

//角色管理列表查询
export function getRoleList(params) {
    return request.post('/system/roleList?' + objectToQueryString(params))
}

//新建角色
export function addRole(body) {
    return request.post('/system/addRoleInfo', body)
}

//编辑保存角色
export function updateRole(body) {
    return request.post('/system/editRoleInfo', body)
}

//删除角色
export function removeRole(body) {
    return request.post('/system/delRole', body)
}

//获取角色权限列表
export function getRolePermission(roleId) {
    return request.get('/system/findPermissionInRole?roleId=' + roleId)
}

//获取权限列表
export function getPermission(params) {
    return request.post('/system/powerList?' + objectToQueryString(params))
}

//添加权限
export function addPermission(body) {
    // return request.post('/system/addPower?' + objectToQueryString(body));
    return request.post('/system/addPower?', body);
}

//编辑保存权限
export function updatePermission(body) {
    return request.post('/system/editPower?', body);
}

//删除权限
export function removePermission(body) {
    return request.post('/system/delPower', body)
}

// 保存角色权限
export function saveRolePermission(body) {
    return request.post('/system/editRole', body);
}

export function getUserPermissions() {
    return request.post('/role/menuList')

}
