<template>
  <div class="search">
    <div style="font-size: 18px;color: #000000;padding-top: 44px;margin-left: 16px;">请输入手机号</div>
    <div style="
      border-radius: 8px;
      margin: 25px 16px;
      background: #FFFFFF;">
      <div style="border: 2px solid #fff;
        border-radius: 6px;">
        <div style="height: 40px;
          margin: 16px 24px;
          display: flex;
          align-items: center;
          border-bottom: #E6EBF0 1px solid">
          <img style="width: 16px;height: 16px;" src="@/assets/images/phone.png">
          <input style="border: #FFFFFF 1px solid;
                font-size: 16px;
                flex: 1;
                margin-left: 12px;"
                v-model="phoneNo"
                 placeholder="请输入手机号"/>
        </div>

      </div>
    </div>
    <div v-if="allowedDriveIn" style="font-size: 18px;margin: 0 16px;color: #3A8AFF;font-weight: 500;">操作成功。</div>
    <div style="margin: 25px 16px;">
      <div v-if="!allowedDriveIn" style="margin-top:36px;width: 100%;height: 43px;background: #3A8AFF;border-radius: 4px;font-size: 16px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;"
        @click="driveIn">
        入场
      </div>
      <div v-if="allowedDriveIn" style="margin-top:16px;width: 100%;height: 43px;background: rgba(58, 138, 255, 0.08);border-radius: 4px;font-size: 16px;display: flex;align-items: center;justify-content: center;color: #000000;"
           @click="closePage">
        我知道了
      </div>
    </div>
  </div>
</template>
<script>
import {
  noPlateNumDriveIn,
} from "@/api/system";
// import wx from "_weixin-jsapi@1.1.0@weixin-jsapi";
import wx from "weixin-jsapi";


export default {
  data() {
    return {
      parkinglotId: '',
      parkingGateId: '',
      activeIndex: 0,
      plateColor:'02',
      phoneNo: '',
      allowedDriveIn: false,
    }
  },
  components:{
    // Numplate,
    // WechatOrder,
  },
  mounted() {
    this.parkinglotId = this.$route.query.parkinglotId;
    this.parkingGateId = this.$route.query.parkingGateId;
  },
  methods: {
    //关闭页面
    closePage() {
      if(this.isWeixinClient()){
        wx.closeWindow()
      }else if(this.isAlipayClient()){
        // eslint-disable-next-line no-undef
        AlipayJSBridge.call('closeWebview');
      }
    },
    isValidPhone() {
      // 中国大陆手机号码正则表达式
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(this.phoneNo);
    },
    driveIn(){
      var that = this
      if(!this.isValidPhone()){
        this.$message.warning('手机号有误，请重新输入')
        return
      }
      noPlateNumDriveIn({
        phone: this.phoneNo,
        parkingLotId: this.parkinglotId,
        parkingLotGateId: this.parkingGateId,
      }).then(res =>{
          if (res && res.code === 30) {
            that.allowedDriveIn = true;
          }
        })
    },
  }
}
</script>


<style scoped>
.search{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #F7F8FA;
  background-image: url("../../assets/images/search_bg.png");
  background-repeat: repeat-x;
  background-size: 100vw;
}

input:focus {
  border-bottom: 3px solid #fff;
  transition: all 0.5s;
}
</style>
